<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        {{ $t("AUTH.GENERAL.HAVING_ACCOUNT") }}
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'login' }"
      >
        {{ $t("AUTH.LOGIN.BUTTON") }}
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <img :src="logo" class="max-w-100px align-center" alt="" />
        <br /><br /><br /><br />
        <h3 class="font-size-h1">{{ $t("AUTH.REGISTER.TITLE") }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t("AUTH.REGISTER.ENTER_ACCOUNT") }}
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
                id="fullName-group"
                label="Nama Lengkap"
                label-for="fullName"
            >
              <b-form-input
                  class="form-control form-control-solid h-auto py-5 px-6"
                  id="fullName"
                  name="fullName"
                  v-model="$v.form.fullName.$model"
                  :state="validateState('fullName')"
                  aria-describedby="fullName-live-feedback"
                  placeholder="Nama Lengkap"
              ></b-form-input>
              <div class="text-danger" v-if="validationform.name" >{{ validationform.name[0] }}</div>
              <b-form-invalid-feedback id="fullName-live-feedback">
                Masukkan Nama Lengkap Anda.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="email-group" label="Email" label-for="email">
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                id="email"
                name="email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-live-feedback"
                placeholder="Email address"
              ></b-form-input>
              <div class="text-danger" v-if="validationform.email" >{{ validationform.email[0] }}</div>
              <b-form-invalid-feedback id="email-live-feedback">
                Email is required and a valid email address.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="userName-group"
              label="Username"
              label-for="userName"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                id="userName"
                name="userName"
                v-model="$v.form.userName.$model"
                :state="validateState('userName')"
                aria-describedby="userName-live-feedback"
                placeholder="User Name"
              ></b-form-input>
              <div class="text-danger" v-if="validationform.username" >{{ validationform.username[0] }}</div>
              <b-form-invalid-feedback id="userName-live-feedback">
                Username is required and a valid username.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="password-group"
              label="Password"
              label-for="password"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                type="password"
                id="password"
                name="password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="password-live-feedback"
                placeholder="Password"
              ></b-form-input>
              <b-form-invalid-feedback id="password-live-feedback">
                Password is required.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="password-confirm-group"
              label="Confirm Password"
              label-for="confirmpassword"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                type="password"
                id="passwordconfirm"
                name="passwordconfirm"
                v-model="$v.form.passwordconfirm.$model"
                :state="validateState('passwordconfirm')"
                aria-describedby="password-confirm-live-feedback"
                placeholder="Confirm Password"
              ></b-form-input>

              <b-form-invalid-feedback id="password-live-feedback">
                Password unmatch.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              ref="kt_login_signup_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
            >
              Submit
            </button>
            <button
              v-on:click="$router.push('login')"
              class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
            >
              Cancel
            </button>
            <!--end::Action-->
          </div>
        </div>
      </b-form>
      <!--end::Form-->

      <b-modal ref="modal-cek-email" id="modalcekemail" size="sm">
        <div class="d-block text-center">
          <h3>
            {{ $t("AUTH.REGISTER.REJECT_EMAIL") }}
          </h3>
        </div>
        <template #modal-footer="{  }">
          <b-button size="lg" variant="danger" @click="hideModalEmail()">
            TUTUP
          </b-button>
        </template>
      </b-modal>

      <b-modal ref="modal-cek-username" size="sm">
        <div class="d-block text-center">
          <h3>
            {{ $t("AUTH.REGISTER.REJECT_USERNAME") }}
          </h3>
        </div>
        <template #modal-footer="{  }">
          <b-button size="lg" variant="danger" @click="hideModalUsername()">
            TUTUP
          </b-button>
        </template>
      </b-modal>

      <b-modal ref="modal-yes-register" size="sm">
        <div class="d-block text-center">
          <h3>
            {{ $t("AUTH.REGISTER.ACCEPT_REGISTER") }}
          </h3>
          <br />
          <h3>
            {{ $t("AUTH.REGISTER.ACCEPT_REGISTER2") }}
          </h3>
        </div>
        <template #modal-footer="{  }">
          <b-button size="lg" variant="danger" @click="hideModalRegis()">
            TUTUP
          </b-button>
        </template>
      </b-modal>
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import {
  GET_EDUCATION,
  GET_GENDER,
  GET_IDENTITY_TYPE,
  SET_EDUCATION,
  SET_GENDER,
  SET_IDENTITY_TYPE
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import * as axios from "axios";

export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
        passwordconfirm: "",
        userName: "",
        fullName: "",
        birthPlace: "",
        birthday: "",
        gender: null,
        education: null,
        identityType: null,
        identityNo: "",
        phone: "",
        address: "",
        idimage: null
      },
      logo: process.env.BASE_URL + "media/logos/komnasham.png",
      educations: [],
      gender: [],
      identityType: [],
      redirectToken: "",
      validationform :{
        email: [],
        username : [],
        name: [],
        birth_date: [],
        birth_place: [],
        identity_number: [],
        address: [],
        phone_no: []
      }
    };
  },
  validations: {
    form: {
      userName: {
        required,
        isNameValid: helpers.regex('isNameValid',/^[a-zA-Z0-9_.]*$/i),
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        // isNameValid: helpers.regex('isNameValid',/^.*(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/i),
        minLength: minLength(8)
      },
      passwordconfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
      fullName: {
        required,
        minLength: minLength(5)
      }
    }
  },
  methods: {
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_EDUCATION) {
          this.educations = this.getEducations;
        }
        if (mutation.type === SET_GENDER) {
          this.gender = this.getGender;
        }
        if (mutation.type === SET_IDENTITY_TYPE) {
          this.identityType = this.getIdentityType;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let formDataCekEmail = new FormData();
      formDataCekEmail.set("email", this.$v.form.email.$model);
      axios.post("/api/smartmap/register/check_email", formDataCekEmail, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(data => {
          if (data.data.data == true) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire(
                'Registrasi User',
                'Email sudah digunakan, mohon gunakan email lain',
                'error'
            );
          } else {
            let formDataCekUsername = new FormData();
            formDataCekUsername.set("user_name", this.$v.form.userName.$model);
            axios.post("/api/smartmap/register/check_user", formDataCekUsername, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              })
              .then((data) => {
                if (data.data.data == true) {
                  submitButton.classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                  );
                  Swal.fire(
                      'Registrasi User',
                      'Username sudah digunakan, mohon gunakan username lain',
                      'error'
                  );
                } else {
                  let formDataRegister = new FormData();
                  formDataRegister.set("username",this.$v.form.userName.$model);
                  formDataRegister.set("email", this.$v.form.email.$model);
                  formDataRegister.set("name", this.$v.form.fullName.$model);
                  formDataRegister.set("password",this.$v.form.password.$model);
                  formDataRegister.set("password_confirmation",this.$v.form.passwordconfirm.$model);

                  axios.post("/api/smartmap/register/data", formDataRegister, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json"
                      }
                    })
                    .then((data) => {
                      this.$data.redirectToken = data.data.data.auth_token;
                      submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                      );
                      this.$refs["modal-yes-register"].show();
                    })
                    .catch((err) => {
                      submitButton.classList.remove(
                          "spinner",
                          "spinner-light",
                          "spinner-right"
                      );
                      if (err.response != undefined) {
                        let msgData = [];
                        if(err.response.data.errors != undefined){
                          for (let x in err.response.data.errors) {
                            msgData.push(err.response.data.errors[x]);
                          }
                        }else{
                          msgData.push(err.response.data.message);
                        }
                        Swal.fire(
                            'Registrasi User',
                            msgData.toString() ,
                            'error'
                        );
                      }else{
                        Swal.fire(
                            'Connection Lost',
                            "Connection to server lost" ,
                            'error'
                        );
                      }
                    });
                }
              });
          }
        });
    },
    hideModalEmail() {
      this.$refs["modal-cek-email"].hide();
      return;
    },
    hideModalUsername() {
      this.$refs["modal-cek-username"].hide();
      return;
    },
    hideModalRegis() {
      this.$refs["modal-yes-register"].hide();
      // this.$router.push({ name: "dashboard" });
      let url = `${process.env.VUE_APP_API_BASE_URL}id/otp-smartmap?token=${encodeURIComponent(this.$data.redirectToken)}`;
      window.location.href = url;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["getEducations", "getGender", "getIdentityType"])
  },
  mounted() {
    this.$store.dispatch(GET_EDUCATION);
    this.$store.dispatch(GET_GENDER);
    this.$store.dispatch(GET_IDENTITY_TYPE);
    this.bindingData();
  }
};
</script>
